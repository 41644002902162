import { createApi, createCustomServiceCall } from '@thinknimble/tn-models'
import { donorCreateShape, donorShape } from './models'
import axiosInstance from '../AxiosClient'
import axios from 'axios'
import { z } from 'zod'
import bbmsMockResponse from '../../data/bbmsMockResponse'

const DONOR_ENDPOINT = '/donors/'

const customCreate = createCustomServiceCall(
  {
    inputShape: donorCreateShape,
    outputShape: donorShape,
  },
  async ({ client, utils, input }) => {
    const { fundraiser, ...data } = input
    const formattedData = utils.toApi(data)
    console.log('api', fundraiser)
    const params = fundraiser ? `?fundraiser=${fundraiser}` : ''
    const res = await client.post(`${DONOR_ENDPOINT}${params}`, formattedData)
    return utils.fromApi(res.data)
  },
)

export const DonorApi = createApi(
  {
    baseUri: DONOR_ENDPOINT,
    client: axiosInstance,

    models: {
      entity: donorShape,
      create: donorCreateShape,
    },
  },
  { customCreate },
)

const externalPPAxios = axios.create({
  baseURL: '',
  headers: {
    'Content-type': 'multipart/form-data',
  },
})

/**
 * this will probably have to be dynamic to handle different forms of payment processors
 */
export const sendCCInfo = createCustomServiceCall.standAlone({
  client: externalPPAxios,
  models: {
    inputShape: {
      formId: z.string().or(z.number()),
      levelId: z.string().or(z.number()),
      cardNumber: z.number().or(z.string()),
      cardCvv: z.number().or(z.string()),
      cardExpDateMonth: z.string(),
      cardExpDateYear: z.string(),
      otherAmount: z.string().or(z.number()),
      dfPreview: z.boolean().optional().nullable(),
      'donor.email': z.string().email(),
      'billing.address.street1': z.string(),
      'billing.address.street2': z.string().optional().nullable(),
      'billing.address.city': z.string(),
      'billing.address.state': z.string(),
      'billing.address.zip': z.string().or(z.number()),
      'billing.name.first': z.string(),
      'billing.name.last': z.string(),
      'donor.email_opt_in': z.string().or(z.boolean()),
      'sustaining.frequency': z.string().optional(),
      'sustaining.duration': z.number().optional(),
      levelAutorepeat: z.boolean().optional(),
      customDomain: z.string(),
      mockPayment: z.string().optional(),
      additionalQuestions: z.record(z.unknown()).optional(),
    },
    outputShape: {
      donationResponse: z.object({
        transactionId: z.string(),
        confirmationCode: z.string(),
        consId: z.string(),
      }),
    },
  },
  name: 'standAlone',

  cb: async ({ client, utils, input }) => {
    let data = utils.toApi(input)
    let customDomain = data.custom_domain
    delete data.custom_domain

    let formData = new FormData()

    const additionalQuestions = data.additional_questions
    delete data.additional_questions

    Object.entries(additionalQuestions).reduce((acc, [key, value]) => {
      acc.append(key, value)
      return acc
    }, formData)

    Object.entries(data).reduce((acc, [key, value]) => {
      acc.append(key, value)
      return acc
    }, formData)

    let res
    if (data.mock_payment == 'true') {
      res = bbmsMockResponse
    } else {
      delete data['mock_payment']
      res = await client.post(customDomain, formData)
    }
    return utils.fromApi(res.data)
  },
})
