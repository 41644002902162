import axiosInstance from '../AxiosClient'
import { createApi } from '@thinknimble/tn-models'
import { npoShape } from './models'

const NPO_ENDPOINT = '/nonprofits/'
export const NPOApi = createApi({
  baseUri: NPO_ENDPOINT,
  client: axiosInstance,
  models: {
    entity: npoShape,
  },
})
