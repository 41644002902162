import axiosInstance from '../AxiosClient'
import { donationShape, donationCreateShape, donationFiltersShape } from './models'
import {
  createApi,
  createCustomServiceCall,
  createCollectionManager,
  Pagination,
} from '@thinknimble/tn-models'
import fileDownload from 'js-file-download'

const DONATIONS_ENDPOINT = '/donations/'

const customCreate = createCustomServiceCall(
  {
    inputShape: donationCreateShape,
    outputShape: donationShape,
  },
  async ({ client, utils, input }) => {
    const { fundraiser, ...data } = input
    let formattedData = utils.toApi(data)
    const params = fundraiser ? `?fundraiser=${fundraiser}` : ''
    const res = await client.post(`${DONATIONS_ENDPOINT}${params}`, formattedData)
    return utils.fromApi(res.data)
  },
)

const generateExport = createCustomServiceCall(
  {
    inputShape: donationFiltersShape,
  },
  async ({ client, utils, input }) => {
    const { ...data } = input
    let formattedData = utils.toApi(data)

    client
      .post(`${DONATIONS_ENDPOINT}generate_donations_export/`, formattedData, {
        responseType: 'blob', // important
      })
      .then((response) => {
        fileDownload(response.data, 'donations_export.csv')
      })
  },
)

export const DonationApi = createApi(
  {
    baseUri: DONATIONS_ENDPOINT,
    client: axiosInstance,
    models: {
      entity: donationShape,
      create: donationCreateShape,
      extraFilters: donationFiltersShape,
    },
  },
  { customCreate, generateExport },
)

export const donationFunctions = () => {
  const donationFilters = {
    ordering: '-givaroo_id',
    campaign: '',
    dateRangeStart: '',
    dateRangeEnd: '',
    fundraiser: '',
    frequency: '',
    status: '',
  }

  const donationCollection = createCollectionManager({
    fetchList: DonationApi.list,
    filters: donationFilters,
    pagination: new Pagination(),
  })

  return {
    donationCollection,
    donationFilters,
  }
}
