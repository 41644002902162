<template>
  <span class="flex justify-center">
    <img class="w-8 animate-spin" src="@/assets/loading.svg" alt="Loading Icon" />
  </span>
</template>

<script>
export default {
  name: 'Spinner',
}
</script>

<style></style>
