import { z } from 'zod'
import { baseModelShape } from '../baseModel'

export const donorCreateShape = {
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email(),
  mobilePhone: z.string().nullable().optional(),
  homePhone: z.string().nullable().optional(),
  dateOfBirth: z.string(),
  isEmailPreferred: z.boolean().optional(),
  isSmsPreferred: z.boolean().optional(),
  isPhoneCallPreferred: z.boolean().optional(),
  addressStreet: z.string(),
  addressDetail: z.string().nullable().optional(),
  city: z.string(),
  state: z.string(),
  postalCode: z.number().or(z.string()),
}

export const donorShape = {
  ...baseModelShape,
  ...donorCreateShape,
}
