const bbmsMockResponse = {
  data: {
    donation_response: {
      donation: {
        transaction_id: '123456',
        confirmation_code: '1234-5678-9-123456-123456',
        cons_id: '987654',
      },
    },
  },
}

export default bbmsMockResponse
