<template>
  <div class="flex flex-col items-center">
    <img alt="Project Logo" src="../assets/logo.png" />
    <h2 class="text-xl font-bold mb-4">
      <Modal
        headerText="Thank you for your donation!"
        ctaText="Close and Reset Form"
        :modalOpen="showSuccess"
        :subheaderText="donateSuccessMessage"
        @closeModal="navigateToHome"
        :showSuccessIcon="true"
      />
    </h2>
  </div>
</template>

<script>
import { useRouter, useRoute } from 'vue-router'
import Modal from '../components/Modal.vue'
import { onMounted } from 'vue'
import { donationActions } from '@/composables/donationSubmissions'
import { ref } from 'vue'
import * as Sentry from '@sentry/vue'

export default {
  name: 'ThankYouPage',
  components: {
    Modal,
  },
  setup() {
    const router = useRouter()
    const { donateSuccessMessage, onSubmitFormsAlt } = donationActions()
    const showSuccess = ref(false)

    onMounted(async () => {
      // get the submission data from the localstorage

      const submissionData = JSON.parse(localStorage.getItem('submissionData'))
      const fundraiserId = localStorage.getItem('fundraiserId')
      // const campaignId = localStorage.getItem('campaignId')

      // if the submission data is not available, redirect to the home page
      // if (!submissionData) {
      //   router.push('/')
      // }
      try {
        await onSubmitFormsAlt(submissionData, fundraiserId)
        donateSuccessMessage.value = `Transaction Id: ${submissionData.response.submissionId}`
        showSuccess.value = true
        localStorage.removeItem('DonorData')
        localStorage.removeItem('submissionData')
        localStorage.removeItem('fundraiserId')
        localStorage.removeItem('campaign')
      } catch (e) {
        console.log(e)
        Sentry.captureException(e)
      }
      // if the submission data is available create the donation
    })

    function navigateToHome() {
      router.push('/')
    }

    return {
      donateSuccessMessage,
      navigateToHome,
      showSuccess,
    }
  },
}
</script>
