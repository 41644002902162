const SUCCESS = 'SUCCESS'
const FAILURE = 'FAILURE'

const DONATION_STATUSES = { SUCCESS: SUCCESS, FAILURE: FAILURE }
const DONATION_STATUS_CHOICES = [
  {
    name: 'Success',
    id: SUCCESS,
  },
  {
    name: 'Failure',
    id: FAILURE,
  },
]

export default {
  SUCCESS,
  FAILURE,
  DONATION_STATUSES,
  DONATION_STATUS_CHOICES,
}
