import { z } from 'zod'

export const frequencyShape = {
  id: z.string().uuid(),
  name: z.string(),
  suggestedAmounts: z.array(z.number()),
  defaultAmount: z.number().nullable(),
  minimum: z.number(),
  value: z.string(),
  minAge: z.number().nullable(),
}
