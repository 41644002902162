import { z } from 'zod'
import { npoShape } from '../npo'
import { readonly } from '@thinknimble/tn-models'
import { baseModelShape } from '../baseModel'
import { frequencyShape } from '../frequencies'
import { canvassingOrgShape, userShape } from '../users'

export const paymentProviderShape = {
  id: z.string().uuid(),
  formId: z.string().or(z.number()),
  levelId: z.string().or(z.number()),
  paymentProvider: z.string().or(z.number()),
  paymentProviderName: readonly(z.string().optional().nullable()),
  campaign: z.string().uuid(),
  customDomain: z.string().nullable(),
}

export const campaignShape = {
  ...baseModelShape,
  name: z.string(),
  frequencies: z.array(z.object(frequencyShape)),
  npo: z.object(npoShape),
  fundraisers: z.array(z.object(userShape)),
  background: z.string().url().nullable(),
  latestPaymentProvider: z.object(paymentProviderShape).nullable(),
  testMode: z.boolean(),
  active: z.boolean(),
  collectingLeads: z.boolean(),
  collectingDonations: z.boolean(),
  canvassingOrg: z.object(canvassingOrgShape),
}

export const campaignRetrieveShape = {
  campaign: z.string(),
  fundraiser: z.string(),
}

export const campaignUpdateShape = {
  id: z.string(),
  fundraisers: z.array(z.string()),
}
