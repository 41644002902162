<template>
  <TransitionRoot as="template" :show="modalOpen">
    <Dialog as="div" class="relative z-10">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6"
            >
              <div>
                <div
                  v-if="showSuccessIcon || showFailureIcon"
                  class="mx-auto flex h-12 w-12 items-center justify-center rounded-full"
                  :class="{
                    'bg-green-100': showSuccessIcon,
                    'bg-red-100': showFailureIcon,
                  }"
                >
                  <CheckIcon
                    v-if="showSuccessIcon"
                    class="h-6 w-6 text-green-600"
                    aria-hidden="true"
                  />
                  <XMarkIcon v-else class="h-6 w-6 text-red-600" aria-hidden="true" />
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">{{
                    headerText
                  }}</DialogTitle>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500">
                      {{ subheaderText }}
                    </p>
                  </div>
                  <div v-if="modalImgUrl" class="text-center mt-4">
                    <img :src="modalImgUrl" alt="QR code to share donation" class="inline-block" />
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-6">
                <button type="button" class="btn--primary w-full" @click="closeModal">
                  {{ ctaText }}
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { CheckIcon, XMarkIcon } from '@heroicons/vue/24/outline'

export default {
  name: 'Modal',
  components: {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    CheckIcon,
    XMarkIcon,
  },
  props: {
    headerText: {
      type: String,
      default: '',
    },
    ctaText: {
      type: String,
      default: '',
    },
    subheaderText: {
      type: String,
      default: '',
    },
    modalOpen: {
      type: Boolean,
    },
    modalImgUrl: {
      type: String,
      default: '',
    },
    showSuccessIcon: {
      type: Boolean,
    },
    showFailureIcon: {
      type: Boolean,
    },
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
    },
  },
}
</script>
