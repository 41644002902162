import axiosInstance from '../AxiosClient'
import {
  createApi,
  createCollectionManager,
  Pagination,
  createCustomServiceCall,
} from '@thinknimble/tn-models'
import {
  workedHoursShape,
  updatedWorkedHourShape,
  customRetrieveWorkedHourInputShape,
  rangeRetrieveInputShape,
} from './models'

const DAILY_WORKED_HOURS_ENDPOINT = '/daily-work-hours/'

const customRetrieve = createCustomServiceCall(
  {
    inputShape: customRetrieveWorkedHourInputShape,
    outputShape: workedHoursShape,
  },
  async ({ client, input, utils: { toApi } }) => {
    const { campaign, date } = toApi(input)
    const response = await client.get(`${DAILY_WORKED_HOURS_ENDPOINT}`, {
      params: {
        campaign,
        date,
      },
    })
    return response.data
  },
)

const rangeRetrieve = createCustomServiceCall(
  {
    inputShape: rangeRetrieveInputShape,
    outputShape: workedHoursShape,
  },
  async ({ client, input, utils: { toApi } }) => {
    const { start_date, end_date, campaign } = toApi(input)
    console.log('Date:', start_date, end_date, campaign)
    const response = await client.get(`${DAILY_WORKED_HOURS_ENDPOINT}`, {
      params: {
        start_date,
        end_date,
        campaign,
      },
    })
    return response.data
  },
)
const customUpdate = createCustomServiceCall(
  {
    inputShape: updatedWorkedHourShape,
    outputShape: workedHoursShape,
  },
  async ({ client, input, utils: { toApi } }) => {
    const { id, ...rest } = toApi(input)
    await client.patch(`${DAILY_WORKED_HOURS_ENDPOINT}${id}/`, rest)
  },
)
export const WorkedHoursApi = createApi(
  {
    baseUri: DAILY_WORKED_HOURS_ENDPOINT,
    client: axiosInstance,
    models: {
      entity: workedHoursShape,
    },
  },
  { customUpdate, customRetrieve, rangeRetrieve },
)

export const workedHourFunctions = () => {
  const workedHourCollection = createCollectionManager({
    fetchList: WorkedHoursApi.list,
    pagination: null,
  })

  return {
    workedHourCollection,
  }
}
