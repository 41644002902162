import axiosInstance from '../AxiosClient'
import { createApi, createCollectionManager, createCustomServiceCall } from '@thinknimble/tn-models'
import { campaignShape, campaignRetrieveShape, campaignUpdateShape } from './models'

const CAMPAIGN_ENDPOINT = '/campaigns/'

const customRetrieve = createCustomServiceCall(
  {
    inputShape: campaignRetrieveShape,
    outputShape: campaignShape,
  },
  async ({ client, utils, input }) => {
    const params = input.fundraiser ? `?fundraiser=${input.fundraiser}` : ''
    const res = await client.get(`${CAMPAIGN_ENDPOINT}${input.campaign}/${params}`)
    return utils.fromApi(res.data)
  },
)

const customUpdate = createCustomServiceCall(
  {
    inputShape: campaignUpdateShape,
    outputShape: campaignShape,
  },
  async ({ client, input, utils: { toApi, fromApi } }) => {
    const { id, ...rest } = toApi(input)
    const res = await client.patch(`${CAMPAIGN_ENDPOINT}${id}/`, rest)
    return fromApi(res.data)
  },
)

export const CampaignApi = createApi(
  {
    baseUri: CAMPAIGN_ENDPOINT,
    client: axiosInstance,
    models: {
      entity: campaignShape,
    },
  },
  { customRetrieve, customUpdate },
)

export const campaignFunctions = () => {
  const campaignFilters = {
    ordering: '-givaroo_id',
  }

  const campaignCollection = createCollectionManager({
    fetchList: CampaignApi.list,
    filters: campaignFilters,
  })

  return {
    campaignCollection,
    campaignFilters,
  }
}
